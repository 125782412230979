@use 'styles/mixins';
@use 'styles/variables' as *;

.ccSelectorModal {
  position: fixed;
  z-index: 400;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  @include mixins.media {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ccSelectorModal:hover {
  overflow: hidden;
}

.ccSelectorContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 92vw;
  height: 316px;
  padding: 2.5rem 2rem;
  background: #f9f9f9;
  border-radius: 0.2rem;

  @include mixins.tablet {
    width: 80vw;
    height: 350px;
    padding: 3rem 2.5rem;
  }

  @include mixins.media {
    width: 600px;
    height: 300px;
    padding: 55px 40px;
  }
}

.updateContent {
  font-size: 24px;
  text-align: left;

  color: #3c3c3b;
  @include mixins.media {
    font-size: 28px;
  }
}

.countryDetection {
  font-size: 14px;
  line-height: 20px;
  margin: auto 0;
  span {
    font-weight: 600;
  }
}

.controlsContainer {
  display: grid;
  column-gap: 0.8rem;
  row-gap: 1rem;
  @include mixins.media {
    grid-template-columns: 1fr auto;
    row-gap: 2em;
  }
}

.confirmContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  background: #3c3c3b;
  border-radius: 0.2rem;
  font-size: 14px;
  color: #ffffff;

  @include mixins.media {
    width: 172px;
    margin: 0;
  }
}

.countryCode {
  margin-right: 1.1rem;
}
.confirmationButtonContent {
  position: relative;
  width: 100%;
  height: 48px;
  background: #ebebeb;
  border-radius: 0.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include mixins.bodyTypography;
  font-size: 14px;

  @include mixins.media {
    width: 330px;
    margin: 0;
  }
}

.locationsContainer {
  position: absolute;
  top: 48px;
  width: 100%;
  max-height: 240px;
  overflow-x: hidden;
  z-index: 100;

  @include mixins.media {
    position: absolute;
    width: 330px;
    margin-left: auto;
  }
}
.countrySelector {
  width: inherit;
  height: 48px;
  background: #ebebeb;
  display: flex;
  align-items: center;
  padding-left: 15px;
  overflow: hidden;
  @include mixins.bodyTypography;
  font-family: $font-headline;
  font-size: 14px;
}

.confirmationButtonContent:hover,
.countrySelector:hover {
  cursor: pointer;
}

.countrySelector:hover {
  background: #ffffff;
}

.button {
  @include mixins.standardButton;
  font-size: 14px;
  padding: 0 0 0 15px;
}

.resetMargin {
  margin: 0;
}

.chevronContainer {
  margin-right: 1rem;

  @include mixins.media {
    margin-right: 15px;
  }
}

.languageSelector {
  display: flex;
  gap: 1rem;
  grid-column: 1 / -1;
  grid-row: 2;

  .languageOption {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
    @include mixins.bodyTypography;
    font-size: 14px;
    color: #3c3c3b;
    input {
      /* Reset base appearance of radio button*/
      appearance: none;
      /* For iOS < 15 to remove gradient background */
      background-color: #fff;
      display: grid;
      place-content: center;
      margin: 0;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      border: 0.1px solid $keune-lightgrey;
      box-sizing: border-box;

      &::before {
        content: '';
        width: 1rem;
        height: 1rem;
        background-color: $keune-black;
        margin: 0.3rem;
        border-radius: 50%;
        transform: scale(0);
      }

      &:checked::before {
        transform: scale(1);
      }
    }
  }
}
